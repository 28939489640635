import { COLOURS } from '@core-ui/styles';
import { IMenuOption } from '@/components/Menu/Menu';
import MoreButton from '@/components/MoreButton';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { CreateEditRequestModalData } from '@/pages/backoffice/Modals/command';
import { NotePencil, Trash } from '@phosphor-icons/react';
import React from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import DeleteRequestModalData from '../../../../../Modals/command/DeleteRequestModalData';

interface IProps {
  isRequestEnabled?: boolean;
}

const SingleRequestMoreButton: React.FC<IProps> = (props) => {
  const { isRequestEnabled } = props;

  const dispatch = useDispatch();

  const handleOpenCreateRequestModal = () => {
    dispatch(openBackofficeModal(new CreateEditRequestModalData()));
  };

  const handleDeleteRequest = () => {
    dispatch(openBackofficeModal(new DeleteRequestModalData()));
  };

  const options: IMenuOption[] = [
    {
      title: <FormattedMessage id="request_detail.info.edit_request" />,
      icon: <NotePencil />,
      onClick: handleOpenCreateRequestModal,
      visible: isRequestEnabled,
    },
    {
      title: <FormattedMessage id="request_detail.info.delete_request" />,
      textColor: COLOURS.HUE_STRAWBERRY.HUE_100,
      icon: <Trash color={COLOURS.HUE_STRAWBERRY.HUE_100} />,
      onClick: handleDeleteRequest,
      visible: true,
    },
  ];

  return <MoreButton options={options} variant="square" padding={4} />;
};

export default SingleRequestMoreButton;
