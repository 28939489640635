import { RequestMinimalOutputSchema, RequestSortingColumns } from '@/generated';
import Immutable from '@core-ui/immutable';
import reducer, { List } from '@core-ui/reducers_handlers';
import { Nullable } from '@core-ui/types';
import { Action, handleActions } from 'redux-actions';

import * as actions from './actions';

export interface IRequestList extends List<RequestMinimalOutputSchema> {
  orderBy: Nullable<RequestSortingColumns>;
  // TODO: в новой ручке (если она будет) нужно переделать boolean на SORT_ORDER_ENUM для консистентности апи между всеми модулями. Изменить в редюсере, экшенах, селекторах и сагах
  desc: boolean;
  paginationPage: number;
}

const defaultState = Immutable<IRequestList>({
  loaded: false,
  hasData: false,
  loading: false,
  chunkLoading: false,
  isAllDataReceived: false,
  orderBy: RequestSortingColumns.CREATED_AT,
  desc: true,
  paginationPage: 0,
});

export default handleActions<typeof defaultState, any>(
  {
    [actions.setOrderBy.toString()]: (state, { payload }: Action<RequestSortingColumns>) => {
      return state.set('orderBy', payload);
    },
    [actions.setDesc.toString()]: (state, { payload }: Action<boolean>) => {
      return state.set('desc', payload);
    },
    [actions.setRequestListPaginationPage.toString()]: (state, { payload }: Action<number>) => {
      return state.set('paginationPage', payload);
    },
  },
  reducer<typeof defaultState>(actions.reducerName, defaultState)
);
