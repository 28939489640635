import { vehicleIdSelector } from 'app/selectors';
import { getAmountFormatter, getCurrencyCodeById } from 'dictionary/selector';
import { getRequestList } from 'pages/backoffice/Requests/RequestsList/selector';
import { createSelector } from 'reselect';

export default createSelector(
  [getRequestList, vehicleIdSelector, getAmountFormatter, getCurrencyCodeById],
  (requestList, boatId, amountFormatter, currencyCodeGetter) => ({
    items: requestList.value,
    boatId,
    amountFormatter,
    currencyCodeGetter,
  })
);
