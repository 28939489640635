import Tabs, { ITab } from '@core-ui/tabs';
import { buildURL } from '@core-ui/url';
import { ROUTES } from '@/app/consts/routes';
import { PAYMENT_REQUEST_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { DETAIL_REQUEST_TABS, QUOTES_COMPARE, QUOTES_TAB_NAME } from '@/pages/backoffice/Requests/SingleRequest/types';

interface IProps {
  boatId: string;
  tabId: DETAIL_REQUEST_TABS;
  requestId: string;
  chatId: string;
  requestType: PAYMENT_REQUEST_TYPE_ENUM;
}

const SingleRequestTabs: FC<IProps> = (props) => {
  const { boatId, tabId, requestId, requestType, chatId } = props;

  const showQuotesTab = requestType !== PAYMENT_REQUEST_TYPE_ENUM.EMERGENCY;

  const tabs = [
    {
      value: DETAIL_REQUEST_TABS.ABOUT,
      label: <FormattedMessage id="request_detail.tabs.about" />,
      to: buildURL(
        `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${requestId}/${DETAIL_REQUEST_TABS.ABOUT}`
      ),
    },
    showQuotesTab && {
      value: DETAIL_REQUEST_TABS.QUOTES,
      label: <FormattedMessage id="request_detail.tabs.quotes" />,
      to: buildURL(
        `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${requestId}/${DETAIL_REQUEST_TABS.QUOTES}`,
        {
          [QUOTES_TAB_NAME]: QUOTES_COMPARE.COMPARE,
        }
      ),
    },
    {
      value: DETAIL_REQUEST_TABS.INVOICES,
      label: <FormattedMessage id="request_detail.tabs.invoices" />,
      to: buildURL(
        `/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${requestId}/${DETAIL_REQUEST_TABS.INVOICES}`
      ),
    },
    {
      value: ROUTES.CHATS,
      label: <FormattedMessage id="request_detail.tabs.chat" />,
      to: buildURL(`/${boatId}/${ROUTES.CHATS}/${chatId}`),
    },
  ].filter(Boolean) as ITab[];

  return <Tabs tabs={tabs} active={tabId} />;
};

export default SingleRequestTabs;
