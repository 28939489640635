import { getData, setData, setItemLoading } from '@core-ui/reducers_handlers';
import { INVOICE_FILE_UPLOAD_TYPE_ENUM, IPaymentRequest, QUOTE_STATUS_ENUM } from '@/pages/backoffice/Requests/types';
import { createAction } from 'redux-actions';

export const reducerName = 'SingleRequest.request';

export interface IUploadDigitized {
  invoiceFiles?: File[];
  quoteFiles?: File[];
  requestFiles?: File[];
  invoiceFileSelectedType?: INVOICE_FILE_UPLOAD_TYPE_ENUM;
  requestId: number | string;
}

export interface IGetPaymentRequest {
  requestId: number | string;
}

export const getRequest = getData<IGetPaymentRequest>(reducerName);
export const setRequest = setData<IPaymentRequest>(reducerName);

export const uploadToDigitized = createAction<IUploadDigitized>('UPLOAD_TO_DIGITIZED');

export interface IUpdateQuoteStatus {
  requestId: string;
  documentIds: number[];
  status: QUOTE_STATUS_ENUM;
}

export const updateQuoteStatus = createAction<IUpdateQuoteStatus>('UPDATE_QUOTE_STATUS');
export const setLoadingButton = setItemLoading(reducerName);

export interface IUseQuoteAsInvoice {
  requestId: string;
  documentId: number;
  invoiceType: INVOICE_FILE_UPLOAD_TYPE_ENUM;
}

export const quoteAsInvoice = createAction<IUseQuoteAsInvoice>(`${reducerName}.AS_INVOICE`);

export interface ISendToXero {
  requestId: number;
  clientCostArticleCode: number;
  invoiceDocumentId: number;
  secondTry?: boolean;
}

export const sendToERP = createAction<ISendToXero>(`${reducerName}.SEND_TO_ERP`);
export const setERPLoading = createAction<boolean>(`${reducerName}.SET_ERP_LOADING`);
