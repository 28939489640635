import { currencyIntlSelector, shouldShowRub } from '@/app/selectors';
import { createSelector } from 'reselect';
import { getInvoices } from '@/pages/backoffice/Requests/SingleRequest/InvoicesList/selector';

const getTotal = createSelector([getInvoices, currencyIntlSelector], (invoices, currencyIntl) =>
  currencyIntl().format(
    invoices.reduce((acc, invoice) => {
      acc += invoice.total_price || 0;

      return acc;
    }, 0)
  )
);
export default createSelector([shouldShowRub, getTotal], (shouldShowRub, total) => ({
  shouldShowRub,
  total,
}));
