import { HeaderCell, TableRow } from '@core-ui/table';
import { SORT_ORDER_ENUM } from '@core-ui/types';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import { useAppSelector } from 'hooks/useAppSelector';
import { getRequestList, setDesc, setOrderBy } from 'pages/backoffice/Requests/RequestsList/actions';
import { HEADERS } from 'pages/backoffice/Requests/RequestsList/components/RequestsListTableHeader/consts';
import { getRequestListSortOrder } from 'pages/backoffice/Requests/RequestsList/selector';
import React from 'react';
import { batch, useDispatch } from 'react-redux';

const RequestsListTableHeader = () => {
  const { orderBy, desc } = useAppSelector(getRequestListSortOrder);

  const dispatch = useDispatch();

  const handleChangeSort = (value?: string) => () => {
    if (!value) {
      return;
    }

    batch(() => {
      if (orderBy !== value) {
        dispatch(setDesc(true));
      } else {
        dispatch(setDesc(!desc));
      }

      dispatch(setOrderBy(value));
      dispatch(getRequestList(null));
    });
  };

  return (
    <TableHead>
      <TableRow>
        {HEADERS.map((column) => (
          <HeaderCell
            key={column.key}
            align={column.align}
            right={column.right}
            shadow={column.shadow}
            sticky
            isActive={orderBy === column.key}
            onChangeSort={column.orderBy ? handleChangeSort(column.orderBy) : undefined}
            order={desc ? SORT_ORDER_ENUM.DESC : SORT_ORDER_ENUM.ASC}
            sx={{
              cursor: column.orderBy ? 'pointer' : 'initial',
              minWidth: column.width,
              width: column.width,
              maxWidth: column.width,
            }}
          >
            <Box>{column.title}</Box>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default RequestsListTableHeader;
