import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  nameCell: {
    overflowWrap: 'anywhere',
    cursor: 'pointer',
  },

  cell: {
    // substitute for deprecated word-break: break-word rule
    overflowWrap: 'anywhere',
  },
});
