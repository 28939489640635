import Button from '@core-ui/button';
import { Nullable } from '@core-ui/types';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { UploadInvoicesModalData } from '@/pages/backoffice/Modals/command';
import { UploadSimple } from '@phosphor-icons/react';
import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import UploadQuotesModalData from '../../../../Modals/command/UploadQuotesModalData';

const UploadDocumentButton: FC = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<Nullable<HTMLDivElement>>(null);
  const dispatch = useDispatch();

  const options = [
    {
      component: 'Quote',
      action: () => {
        setOpen(false);
        dispatch(openBackofficeModal(new UploadQuotesModalData()));
      },
    },
    {
      component: 'Invoice',
      action: () => {
        setOpen(false);
        dispatch(openBackofficeModal(new UploadInvoicesModalData()));
      },
    },
  ];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: TouchEvent | MouseEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          color="blue"
          variant="outlined"
          onClick={handleToggle}
          startIcon={<UploadSimple />}
          label="Upload document"
        />
      </ButtonGroup>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 3 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              minWidth: 195,
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, idx) => (
                    <MenuItem key={idx} disableRipple onClick={option.action}>
                      {option.component}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UploadDocumentButton;
