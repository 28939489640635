import filters, { IRequestsFiltersState } from 'pages/backoffice/Requests/RequestsFilters/reducer';
import list, { IRequestList } from 'pages/backoffice/Requests/RequestsList/reducer';
import singleRequest, { ISingleRequestState } from '@/pages/backoffice/Requests/SingleRequest/reducer';
import { combineReducers } from 'redux';

export interface IRequestsState {
  filters: IRequestsFiltersState;
  list: IRequestList;
  singleRequest: ISingleRequestState;
}

export default combineReducers({
  filters,
  list,
  singleRequest,
});
