import { IQuote } from '@/pages/backoffice/Requests/types';
import { createAction } from 'redux-actions';
import { IQuoteChecked } from '../types';

export const reducerName = 'SingleRequest.compare';

export interface ISetCompareQuotes {
  checkedQuoteIds: Record<number, IQuoteChecked>;
  quotes: IQuote[];
}

export const setCompareQuotes = createAction<ISetCompareQuotes>(`${reducerName}.set_compare_quotes`);

export interface ICheckedCompare {
  id: number;
  checked: boolean;
}

export const setCheckedQuoteById = createAction<ICheckedCompare>(`${reducerName}.set_checked_quote_by_id`);

export interface ICompareQuotes {
  quotes: IQuote[];
}

export const compareQuotes = createAction<ICompareQuotes>(`${reducerName}.compare_quotes`);

export interface IRadioCheckedCompareQuotes {
  id: number;
}

export const setRadioCheckedQuotesById = createAction<IRadioCheckedCompareQuotes>(
  `${reducerName}.set_radio_checked_quote_by_id`
);

export const deleteQuoteInCompareView = createAction<number>(`${reducerName}.compare_delete_quote`);
export const setCompareView = createAction(`${reducerName}.set_compare_view`);
export const updateQuotes = createAction(`${reducerName}.decline`);
