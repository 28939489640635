import { RequestMinimalOutputSchema } from '@/generated';
import { getChunkList, getList, resetState, setChunkList, setList, setLoading } from '@core-ui/reducers_handlers';
import { createAction } from 'redux-actions';

export const reducerName = 'requests.list';

export const resetRequestsList = resetState(reducerName);

export const getRequestList = getList(reducerName);
export const setRequestList = setList<RequestMinimalOutputSchema>(reducerName);

export const getChunkRequestList = getChunkList(reducerName);
export const setChunkRequestList = setChunkList<RequestMinimalOutputSchema>(reducerName);

export const setOrderBy = createAction<string>(`${reducerName}.SET_ORDER_BY`);
// TODO: в новой ручке (если она будет) нужно переделать boolean на SORT_ORDER_ENUM для консистентности апи между всеми модулями. Изменить в редюсере, экшенах, селекторах и сагах
export const setDesc = createAction<boolean>(`${reducerName}.SET_DESC`);

export const setRequestListLoading = setLoading(reducerName);
export const setRequestListPaginationPage = createAction<number>(`${reducerName}.SET_PAGINATION_PAGE`);
