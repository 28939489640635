import TableBody from '@mui/material/TableBody';
import { useAppSelector } from 'hooks/useAppSelector';
import { RequestListRow } from 'pages/backoffice/Requests/RequestsList/components';
import React from 'react';
import selector from './selector';

const RequestsListTableBody = () => {
  const { items, boatId, amountFormatter, currencyCodeGetter } = useAppSelector(selector);

  return (
    <TableBody>
      {items?.map((item) => (
        <RequestListRow
          key={item.id}
          item={item}
          boatId={boatId}
          amountFormatter={amountFormatter}
          currencyCodeGetter={currencyCodeGetter}
        />
      ))}
    </TableBody>
  );
};

export default RequestsListTableBody;
