import State from 'app/types/state';
import { NoDataContainerSelector } from 'components/NoDataContainer/NoDataContainer';
import { createSelector } from 'reselect';

const getOrderBy = (state: State) => state.backoffice.requests.list.orderBy;
const getDesc = (state: State) => state.backoffice.requests.list.desc;

export const getRequestListSortOrder = createSelector([getOrderBy, getDesc], (orderBy, desc) => ({
  orderBy,
  desc,
}));

export const getRequestListPaginationPage = (state: State) => state.backoffice.requests.list.paginationPage;

export const getRequestList = (state: State) => state.backoffice.requests.list;

export const getNoDataContainerData: NoDataContainerSelector = createSelector([getRequestList], (state) => ({
  loading: state.loading,
  loaded: state.loaded,
  hasData: state.hasData,
  error: state.error,
}));

const getChunkLoading = (state: State) => state.backoffice.requests.list.chunkLoading;
const getAllDataReceived = (state: State) => state.backoffice.requests.list.isAllDataReceived;

export const chunkRequestListLoaderSelector = createSelector(
  [getChunkLoading, getAllDataReceived],
  (isChunkLoading, isAllDataReceived) => ({
    isChunkLoading,
    isAllDataReceived,
  })
);
