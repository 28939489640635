import { createSelector } from 'reselect';
import State from '@/app/types/state';
import { IQuoteLine, REQUEST_DOCUMENTS_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { getBars } from '../../utils';
import { getAverage } from '../LeftSidebar/selector';

const getDocuments = (state: State) => state.backoffice.requests.singleRequest.compareQuote.quotes;

export const getQuotes = createSelector([getDocuments], (quotes) =>
  quotes.filter((document) => document.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE)
);

const getMaxValue = createSelector([getQuotes], (item) => {
  const totals = item.map((el) => getBars(el).map((line: IQuoteLine) => line.total_price)).flat();

  return Math.max(...totals);
});

export default createSelector([getQuotes, getAverage, getMaxValue], (quotes, averageHeight, maxValue) => ({
  quotes,
  averageHeight,
  maxValue,
}));
