import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import ExpandMore from '@/components/ExpandMore';
import StatusBadge from '@/components/StatusBadge';
import ItemExpanded from '@/pages/backoffice/Requests/SingleRequest/components/ItemExpanded';
import { IPaymentRequestFile } from '@/pages/backoffice/Requests/types';
import { HourglassMedium } from '@phosphor-icons/react';
import { FC, useState } from 'react';

interface IProps {
  document: IPaymentRequestFile;
}

const HourGlassCell = () => (
  <Grid item container xs={1} sx={{ backgroundColor: '#F8F8F9' }}>
    <SvgIcon>
      <HourglassMedium />
    </SvgIcon>
  </Grid>
);

const QuoteItemOnDigitized: FC<IProps> = (props) => {
  const { document } = props;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <Grid item container xs={12}>
      <Grid container alignItems="center" columnGap={1} padding="16px 0">
        <Grid item width="36px">
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="body1"
            fontWeight="semiBold"
            noWrap
            sx={{ cursor: 'pointer' }}
            onClick={handleExpandClick}
          >
            {document.filename}
          </Typography>
        </Grid>
        <HourGlassCell />
        <HourGlassCell />
        <HourGlassCell />
        <HourGlassCell />
        <Grid item xs={2}>
          <StatusBadge status={document.status} />
        </Grid>
        <HourGlassCell />
        <HourGlassCell />
        <HourGlassCell />
      </Grid>

      <ItemExpanded expanded={expanded} document={document} />
    </Grid>
  );
};

export default QuoteItemOnDigitized;
