import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import { IRouteParams } from '@/app/consts/routes';
import { useAppSelector } from '@/hooks/useAppSelector';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import AddQuotesToCompareModalData from '@/pages/backoffice/Modals/command/AddQuotesToCompareModalData';
import { updateQuoteStatus } from '@/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import { QUOTE_STATUS_ENUM } from '@/pages/backoffice/Requests/types';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import selector from './selector';
import useStyles from './styles';

interface IProps {}

const QuoteButtons: FC<IProps> = () => {
  const { selectedQuoteIds } = useAppSelector(selector);
  const { requestId } = useParams<IRouteParams>();

  const dispatch = useDispatch();
  const styles = useStyles();

  const openModal = () => {
    dispatch(openBackofficeModal(new AddQuotesToCompareModalData()));
  };

  const clickAllApprove = () =>
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: selectedQuoteIds,
        status: QUOTE_STATUS_ENUM.APPROVED,
      })
    );

  return (
    <Grid container gap={3} justifyContent="flex-end" padding={4} sx={styles.container}>
      <Button
        variant="contained"
        color="blue"
        onClick={clickAllApprove}
        label={<FormattedMessage id="quotes.approve_quotes" />}
        disabled={!selectedQuoteIds.length}
      />

      <Button
        variant="outlined"
        color="blue"
        onClick={openModal}
        label={<FormattedMessage id="quotes.add_quotes_to_compare" />}
      />
    </Grid>
  );
};

export default QuoteButtons;
