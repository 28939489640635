import { COLOURS } from '@core-ui/styles';
import { IRouteParams } from '@/app/consts/routes';
import MoreButton from '@/components/MoreButton';
import { updateQuoteStatus } from '@/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import { QUOTE_STATUS_ENUM } from '@/pages/backoffice/Requests/types';
import { Check, Trash, X } from '@phosphor-icons/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { deleteQuoteInCompareView } from '../../actions';

interface IProps {
  quoteId: number;
  quoteStatus: string;
}

const QuotesCompareMoreButton: FC<IProps> = (props) => {
  const { quoteId, quoteStatus } = props;

  const dispatch = useDispatch();
  const { requestId } = useParams<IRouteParams>();

  const handleDeleteQuote = () => {
    dispatch(deleteQuoteInCompareView(quoteId));
  };

  const handleUpdateQuoteStatusAwaiting = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [quoteId],
        status: QUOTE_STATUS_ENUM.AWAITING,
      })
    );
  };

  const handleUpdateQuoteStatusDeclined = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [quoteId],
        status: QUOTE_STATUS_ENUM.DECLINED,
      })
    );
  };

  const handleUpdateQuoteStatusApproved = () => {
    dispatch(
      updateQuoteStatus({
        requestId,
        documentIds: [quoteId],
        status: QUOTE_STATUS_ENUM.APPROVED,
      })
    );
  };

  const options = [
    {
      title: <FormattedMessage id="quotes.approve_quote" />,
      icon: <Check />,
      onClick: handleUpdateQuoteStatusApproved,
      visible: quoteStatus === QUOTE_STATUS_ENUM.AWAITING,
    },

    {
      title: <FormattedMessage id="quotes.decline_quote" />,
      icon: <X />,
      onClick: handleUpdateQuoteStatusDeclined,
      visible: quoteStatus === QUOTE_STATUS_ENUM.AWAITING,
    },
    {
      title: <FormattedMessage id="label.cancel" />,
      icon: <X color={COLOURS.HUE_STRAWBERRY.HUE_100} />,
      onClick: handleUpdateQuoteStatusAwaiting,
      visible: quoteStatus !== QUOTE_STATUS_ENUM.AWAITING,
    },
    {
      title: <FormattedMessage id="quotes.delete_from_comparison" />,
      icon: <Trash />,
      onClick: handleDeleteQuote,
      visible: true,
    },
  ];

  return <MoreButton options={options} variant="square" padding={2} />;
};

export default QuotesCompareMoreButton;
