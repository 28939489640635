import { HEIGHT_SIZE } from '@core-ui/styles';
import { buildURL } from '@core-ui/url';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ROUTES } from '@/app/consts/routes';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  boatId: string;
}

const containerStyles: SxProps<Theme> = {
  padding: '0 24px !important',
  height: `calc(100vh - (${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px))`,
  overflow: 'hidden',
};

const RequestDetailSkeleton: React.FC<IProps> = (props) => {
  const { boatId } = props;

  return (
    <Container maxWidth="xl" sx={containerStyles}>
      <Grid container direction="column" marginTop={6}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href={buildURL(`/${boatId}/${ROUTES.BACKOFFICE}`)}>
            <Typography variant="body2" color="text.secondary">
              Backoffice
            </Typography>
          </Link>

          <Typography variant="body2" color="text.primary">
            <FormattedMessage id="backoffice.tabs.requests" />
          </Typography>
        </Breadcrumbs>

        <Grid item container flexWrap="nowrap" columnGap={1} alignItems="center">
          <Grid item container marginTop={2}>
            <Skeleton variant="rounded" height={32} width={240} />
          </Grid>

          <Skeleton variant="rounded" height={32} width={160} />
        </Grid>
      </Grid>

      <Grid container marginTop={3} alignItems="center" columnGap={1}>
        <Skeleton variant="rounded" width={140} />
      </Grid>

      <Grid container wrap="nowrap" alignItems="center" marginTop={7}>
        <Grid item xs>
          <Skeleton variant="rounded" height={28} width={358} />
        </Grid>

        <Grid item>
          <Skeleton variant="rounded" height={28} width={100} />
        </Grid>
      </Grid>

      <Paper elevation={0} style={{ padding: 16, margin: '10px 0 24px', flex: 1 }} />
    </Container>
  );
};

export default RequestDetailSkeleton;
