import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from './styles';

const InvoiceListHeader: FC = () => {
  const styles = useStyles();

  return (
    <Grid container paddingBottom={2} columnGap={1} sx={styles.container}>
      <Grid item width="36px">
        {/* collapse button column */}
      </Grid>

      <Grid item xs={2} component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary">
        <FormattedMessage id="request_detail.quotes.headers.name" />
      </Grid>

      <Grid item xs={1} component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary">
        <FormattedMessage id="request_detail.quotes.headers.lead_time" />
      </Grid>

      <Grid item xs={1} component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary">
        <FormattedMessage id="request_detail.quotes.headers.delivery" />
      </Grid>

      <Grid item xs={1} component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary">
        <FormattedMessage id="request_detail.quotes.headers.payments_term" />
      </Grid>

      <Grid item xs={1} component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary">
        <FormattedMessage id="request_detail.quotes.headers.issue_date" />
      </Grid>

      <Grid item xs={2} component={Typography} variant="body2" fontWeight="semiBold" color="text.secondary">
        <FormattedMessage id="request_detail.quotes.headers.status" />
      </Grid>

      <Grid
        item
        xs={1}
        marginLeft="auto"
        textAlign="right"
        component={Typography}
        variant="body2"
        fontWeight="semiBold"
        color="text.secondary"
      >
        <FormattedMessage id="request_detail.quotes.headers.currency" />
      </Grid>

      <Grid
        item
        xs={1}
        marginLeft="auto"
        textAlign="right"
        paddingRight={6}
        component={Typography}
        variant="body2"
        fontWeight="semiBold"
        color="text.secondary"
      >
        <FormattedMessage id="request_detail.quotes.headers.subtotal" />
      </Grid>

      <Grid
        item
        xs={1}
        marginLeft="auto"
        textAlign="right"
        paddingRight={6}
        component={Typography}
        variant="body2"
        fontWeight="semiBold"
        color="text.secondary"
      >
        <FormattedMessage id="request_detail.quotes.headers.total" />
      </Grid>
    </Grid>
  );
};

export default InvoiceListHeader;
