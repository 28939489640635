import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { openBackofficeModal } from '@/pages/backoffice/Modals/actions';
import { UploadInvoicesModalData } from '@/pages/backoffice/Modals/command';
import { UploadSimple } from '@phosphor-icons/react';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import useStyles from './styles';

const NoInvoices: FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const openModal = () => dispatch(openBackofficeModal(new UploadInvoicesModalData()));

  return (
    <Grid
      container
      component={Paper}
      alignItems="center"
      justifyContent="center"
      wrap="nowrap"
      columnGap={2}
      sx={styles.container}
    >
      <Typography color="text.secondary" variant="body1">
        <FormattedMessage id="request_detail.no_documents" />
      </Typography>

      <Button
        color="blue"
        onClick={openModal}
        startIcon={<UploadSimple />}
        label={<FormattedMessage id="request_detail.upload_invoices" />}
      />
    </Grid>
  );
};

export default NoInvoices;
