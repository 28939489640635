import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import { IRouteParams, ROUTES } from '@/app/consts/routes';
import State from '@/app/types/state';
import BackofficeRoot from '@/pages/backoffice/BackofficeRoot';
import { BACKOFFICE_PAGES_PATH, BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { REQUEST_DETAIL_TABS_PATH } from '@/pages/backoffice/Requests/SingleRequest/types';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import contractsRoutes from './Contracts/routes';
import inboxRoutes from './Inbox/routes';
import ledgerRoutes from './Ledger/routes';
import requestsRoutes from './Requests/routes';
import walletsRoutes from './Wallets/routes';

const key: keyof State = 'backoffice';

export const BACKOFFICE_ROUTE: Route = {
  key,
  path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_PAGES_PATH})`,
  component: BackofficeRoot,
  routes: [...inboxRoutes, ...walletsRoutes, ...contractsRoutes, ...requestsRoutes, ...ledgerRoutes],
};

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})`,
    component: (props: RouteComponentProps<IRouteParams>) => (
      <Redirect to={`${props.location.pathname}/${BACKOFFICE_TABS.INBOX}`} />
    ),
  },
  {
    key,
    // deprecated single request route still in use only on the backend
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.PR})/:requestId/:tabId(${REQUEST_DETAIL_TABS_PATH})?`,
    component: (props: RouteComponentProps<IRouteParams>) => (
      <Redirect
        to={`${props.location.pathname.replace(ROUTES.PR, `${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}`)}`}
      />
    ),
  },
  BACKOFFICE_ROUTE,
];

export const BACKOFFICE_PATHS = extractFlattenPaths(routes);

export default routes;
