import { vehicleIdSelector } from '@/app/selectors';
import State from '@/app/types/state';
import { IQuote, REQUEST_DOCUMENTS_TYPE_ENUM } from '@/pages/backoffice/Requests/types';
import { createSelector } from 'reselect';

const paymentRequestSelector = (state: State) => state.backoffice.requests.singleRequest.request.value;

export const quotesBeingDigitizedSelector = createSelector(
  [paymentRequestSelector],
  (request) => request?.files_tree?.filter((file) => file.doc_type !== 'default' && !file.is_digitized) || []
);

const getQuotes = createSelector(
  [paymentRequestSelector],
  (request) =>
    (request?.documents?.filter((document) => document.document_type === REQUEST_DOCUMENTS_TYPE_ENUM.QUOTE) ||
      []) as IQuote[]
);

export default createSelector(
  [getQuotes, vehicleIdSelector, quotesBeingDigitizedSelector],
  (quotes, boatId, quotesBeingDigitized) => ({
    quotes,
    boatId,
    quotesBeingDigitized,
  })
);
