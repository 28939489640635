import { RequestMinimalOutputSchema } from '@/generated';
import { TableCell, TableRow } from '@core-ui/table';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from 'app/consts/app';
import { ROUTES } from 'app/consts/routes';
import { AmountFormatter, CurrencyCodeGetterById } from 'app/types/common';
import { formatTime } from 'app/utils/dates';
import { FormattedMessage } from 'components/Intl';
import { BACKOFFICE_TABS } from 'pages/backoffice/consts';
import { DETAIL_REQUEST_TABS } from 'pages/backoffice/Requests/SingleRequest/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

interface IProps {
  item: RequestMinimalOutputSchema;
  boatId: Nullable<number>;
  amountFormatter: AmountFormatter;
  currencyCodeGetter: CurrencyCodeGetterById;
}

const RequestsListRow = (props: IProps) => {
  const { item, boatId, amountFormatter, currencyCodeGetter } = props;

  const styles = useStyles();
  const history = useHistory();

  const handleOpenRequest = () => {
    history.push(
      buildURL(`/${boatId}/${ROUTES.BACKOFFICE}/${BACKOFFICE_TABS.REQUESTS}/${item.id}/${DETAIL_REQUEST_TABS.ABOUT}`)
    );
  };

  const createdAt = item.created_at ? formatTime(item.created_at, { dateFormat: DATE_FORMAT }) : undefined;

  const createdBy = item.employee_email
    ? item.employee_email
    : [item.employee_first_name, item.employee_last_name].filter(Boolean).join(' ') || (
        <FormattedMessage id="label.no_info" />
      );

  return (
    <TableRow>
      <TableCell onClick={handleOpenRequest} sx={styles.nameCell}>
        {item.name}
      </TableCell>

      <TableCell sx={styles.cell}>
        {/* TODO: сделать красивые баджи когда будет новая ручка с новыми статусами */}
        {item.calculated_status}
      </TableCell>

      <TableCell sx={styles.cell}>{createdAt}</TableCell>

      <TableCell sx={styles.cell}>{createdBy}</TableCell>

      <TableCell sx={styles.cell}>{/* TODO: vendor, нужна новая ручка с этим параметром */}</TableCell>

      <TableCell sx={styles.cell}>{/* TODO: cost_articles, нужна новая ручка с этим параметром */}</TableCell>

      <TableCell sx={styles.cell} align="right">
        <Typography fontFamily="mono" fontWeight="semiBold">
          {currencyCodeGetter(item.amount_currency_id)}
          {amountFormatter(item.amount)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default RequestsListRow;
