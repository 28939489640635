import { createAction } from 'redux-actions';
import {
  FILE_UPLOAD_TYPE,
  INVOICE_CALCULATED_STATUS_ENUM,
  INVOICE_FILE_UPLOAD_TYPE_ENUM,
  PAYMENT_REQUEST_APPROVE_STATUS,
} from '@/pages/backoffice/Requests/types';

export const reducerName = 'SingleRequest.invoices';

export interface IUpdateInvoiceStatus {
  documentId?: number;
  status: INVOICE_CALCULATED_STATUS_ENUM | PAYMENT_REQUEST_APPROVE_STATUS;
  reason?: string;
}

export const updateInvoiceStatus = createAction<IUpdateInvoiceStatus>('UPDATE_INVOICE_STATUS');

export interface IMarkAsPaid {
  documentId: number;
  status: boolean;
}

export const markAsPaid = createAction<IMarkAsPaid>('UPDATE_INVOICE_MARK_AS_PAID');

export interface IUploadInvoices {
  type: INVOICE_FILE_UPLOAD_TYPE_ENUM | FILE_UPLOAD_TYPE;
  files: File[];
}

export const uploadDocument = createAction<IUploadInvoices>(`${reducerName}.UPLOAD_DOCUMENT`);

export const deleteRequest = createAction(`${reducerName}.DELETE_REQUEST`);

export const toggleInvoiceId = createAction<number>(`${reducerName}.TOGGLE_INVOICE_ID`);

export interface IDeleteInvoice {
  documentId: number;
  isDocumentAlreadyDigitized: boolean;
}

export const deleteInvoice = createAction<IDeleteInvoice>(`${reducerName}.DELETE_ALREADY_DIGITIZED_INVOICE`);

export interface ISendInvoicedBatch {
  boatId: number;
  requestId: number;
}

export const sendInvoicesBatch = createAction(`${reducerName}.SEND_INVOICES_BATCH`);
