import { COLOURS } from '@core-ui/styles/consts';
import { SxProps, Theme } from '@mui/material/styles';
import { heightPercent, maxHeightQuoteBar } from '@/pages/backoffice/Requests/SingleRequest/consts';

interface IProps {
  averageHeight: number;
}

export default ({ averageHeight }: IProps): Record<string, SxProps<Theme>> => ({
  quoteContainerHead: { position: 'relative', background: `${COLOURS.BACKGROUND.SECONDARY}` },
  quoteContainerMarker: { position: 'absolute', height: maxHeightQuoteBar, width: '100%', bottom: 0 },
  quoteMarker: {
    position: 'absolute',
    bottom: averageHeight === heightPercent ? maxHeightQuoteBar : `${averageHeight}% `,
    left: 0,
  },
});
