import { GET } from '@/api/oceanApi';
import { responseError } from '@/app/sagas';
import { vehicleIdSelector } from '@/app/selectors';
import { RequestMinimalOutputSchema, RequestSortingColumns } from '@/generated';
import { Nullable } from '@core-ui/types';
import { buildURL } from '@core-ui/url';
import { IRequestsFiltersState } from 'pages/backoffice/Requests/RequestsFilters/reducer';
import { getRequestFilters } from 'pages/backoffice/Requests/RequestsFilters/selector';
import { getRequestListPaginationPage, getRequestListSortOrder } from 'pages/backoffice/Requests/RequestsList/selector';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';

const LIMIT = 30;

interface SortAndOrderDesc {
  orderBy: Nullable<RequestSortingColumns>;
  // TODO: в новой ручке (если она будет) нужно переделать boolean на SORT_ORDER_ENUM для консистентности апи между всеми модулями. Изменить в редюсере, экшенах, селекторах и сагах
  desc: boolean;
}

interface IFetchRequests {
  page: number;
}

function* fetchRequests({ page }: IFetchRequests) {
  const filters: IRequestsFiltersState = yield select(getRequestFilters);
  const boatId: Nullable<number> = yield select(vehicleIdSelector);
  // TODO: добавить сортировку
  const { orderBy, desc }: SortAndOrderDesc = yield select(getRequestListSortOrder);

  const url = buildURL('/requests', {
    page,
    boat_id: boatId,
    per_page: LIMIT,
    order_by: orderBy || undefined,
    desc,

    request_name: filters.name || undefined,
  });

  const requests: RequestMinimalOutputSchema[] = yield call(GET, url);

  return requests;
}

function* getRequestList() {
  try {
    const requests: RequestMinimalOutputSchema[] = yield call(fetchRequests, { page: 0 });

    yield all([
      put(actions.setRequestListPaginationPage(0)),
      put(
        actions.setRequestList({
          value: requests,
          hasData: Boolean(requests.length),
          isAllDataReceived: requests.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield all([
      put(
        actions.setRequestList({
          error: e as Error,
          hasData: false,
          isAllDataReceived: false,
        })
      ),
      call(responseError, e),
    ]);
  }
}

function* getChunkRequestList() {
  try {
    const page: number = yield select(getRequestListPaginationPage);
    const newRequests: RequestMinimalOutputSchema[] = yield call(fetchRequests, { page: page + 1 });

    yield all([
      put(actions.setRequestListPaginationPage(page + 1)),
      put(
        actions.setChunkRequestList({
          value: newRequests,
          isAllDataReceived: newRequests.length < LIMIT,
        })
      ),
    ]);
  } catch (e) {
    yield call(responseError, e);
  }
}

export default [
  takeLatest(actions.getRequestList, getRequestList),
  takeLatest(actions.getChunkRequestList, getChunkRequestList),
];
