import Button from '@core-ui/button';
import { useQuery } from '@core-ui/redux-router';
import { updateQuery } from '@core-ui/url';
import { useAppSelector } from '@/hooks/useAppSelector';
import { ListChecks, SortAscending } from '@phosphor-icons/react';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import { useHistory } from 'react-router-dom';
import { QUOTES_COMPARE, QUOTES_TAB_NAME, SingleRequestQueryParams } from '../../types';
import selector from './selector';

const QuoteViewButton: FC = () => {
  const { hasQuotes } = useAppSelector(selector);
  const { quotes_tab } = useQuery<SingleRequestQueryParams>();
  const history = useHistory();

  const setTab = (tab: QUOTES_COMPARE) => () => {
    history.replace(
      updateQuery({
        [QUOTES_TAB_NAME]: tab,
      })
    );
  };

  switch (quotes_tab) {
    case QUOTES_COMPARE.LIST:
      return (
        <Button
          color="blue"
          variant="outlined"
          startIcon={<ListChecks />}
          label={<FormattedMessage id="quotes.compare_view" />}
          onClick={setTab(QUOTES_COMPARE.COMPARE)}
          disabled={!hasQuotes}
        />
      );
    case QUOTES_COMPARE.COMPARE:
      return (
        <Button
          color="blue"
          variant="outlined"
          startIcon={<SortAscending />}
          label={<FormattedMessage id="quotes.list_view" />}
          onClick={setTab(QUOTES_COMPARE.LIST)}
        />
      );
    default:
      return null;
  }
};

export default QuoteViewButton;
