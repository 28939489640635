import Grid from '@mui/material/Grid';
import QuoteItem from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteItem';
import QuoteItemOnDigitized from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteItemOnDigitized';
import QuoteListHeader from '@/pages/backoffice/Requests/SingleRequest/QuotesList/components/QuoteListHeader';
import { IPaymentRequestFile, IQuote } from '@/pages/backoffice/Requests/types';
import { FC } from 'react';

interface IViewProps {
  quotes: IQuote[];
  quotesBeingDigitized: IPaymentRequestFile[];
}

const QuotesListView: FC<IViewProps> = (props) => {
  const { quotes, quotesBeingDigitized } = props;

  return (
    <>
      <QuoteListHeader />

      <Grid container marginTop="2px" gap="2px">
        {quotes.map((document) => (
          <QuoteItem key={document.id} document={document} />
        ))}

        {quotesBeingDigitized.map((file) => (
          <QuoteItemOnDigitized key={file.id} document={file} />
        ))}
      </Grid>
    </>
  );
};

export default QuotesListView;
