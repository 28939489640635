import { RequestSortingColumns } from '@/generated';
import { FormattedMessage } from 'components/Intl';
import { ITableColumn } from 'pages/backoffice/types';

export const HEADERS: ITableColumn[] = [
  {
    key: 'name',
    title: <FormattedMessage id="table.header.request_name" />,
    orderBy: RequestSortingColumns.NAME,
    width: 320,
  },
  {
    key: 'request_status_id',
    title: <FormattedMessage id="table.header.status" />,
    width: 180,
  },
  {
    key: 'created_at',
    title: <FormattedMessage id="table.header.created_at" />,
    orderBy: RequestSortingColumns.CREATED_AT,
    width: 130,
  },
  {
    key: 'employee_id',
    title: <FormattedMessage id="table.header.creator" />,
    orderBy: RequestSortingColumns.EMPLOYEE_ID,
    width: 130,
  },
  {
    key: 'vendor',
    title: <FormattedMessage id="table.header.vendor" />,
    width: 130,
  },
  {
    key: 'cost_articles',
    title: <FormattedMessage id="table.header.cost_articles" />,
    width: 130,
  },
  {
    key: 'amount',
    title: <FormattedMessage id="table.header.documents_total" />,
    orderBy: RequestSortingColumns.AMOUNT,
    width: 130,
  },
];
