import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CurrencyAmount from '@/components/CurrencyAmount';
import { IInvoice } from '@/pages/backoffice/Requests/types';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';

interface IProps {
  document: IInvoice;
}

const TotalTooltip: FC<IProps> = (props) => {
  const { document } = props;

  return (
    <Grid container direction="column">
      <Grid item container justifyContent="space-between">
        <Typography color={COLOURS.WHITE} fontWeight={500}>
          <FormattedMessage id="request_detail.quotes.line.total_cost" />
        </Typography>

        <Grid item>
          <Typography color={COLOURS.WHITE} fontWeight={500}>
            <CurrencyAmount currencyId={document.total_price_currency_id} amount={document.total_price} />
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justifyContent="space-between">
        <Typography fontWeight={500} variant="body2" color="textSecondary">
          <FormattedMessage id="request_detail.quotes.line.shipping" />
        </Typography>

        <Grid item>
          <Typography fontWeight={500} variant="body2" color="textSecondary">
            <CurrencyAmount currencyId={document.shipping_cost_currency_id} amount={document.shipping_cost} />
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justifyContent="space-between">
        <Typography fontWeight={500} variant="body2" color="textSecondary">
          <FormattedMessage id="request_detail.quotes.line.agency_fee" />
        </Typography>

        <Grid item>
          <Typography fontWeight={500} variant="body2" color="textSecondary">
            <CurrencyAmount currencyId={document.agency_fee_currency_id} amount={document.agency_fee} />
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justifyContent="space-between">
        <Typography fontWeight={500} variant="body2" color="textSecondary">
          <FormattedMessage id="request_detail.quotes.line.vat" />
        </Typography>

        <Grid item>
          <Typography fontWeight={500} variant="body2" color="textSecondary">
            <CurrencyAmount currencyId={document.vat_currency_id} amount={document.vat} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalTooltip;
