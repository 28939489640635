import { HEIGHT_SIZE } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';

export default (): Record<string, SxProps<Theme>> => ({
  container: {
    padding: '0 !important',
    height: `calc(100vh - ${HEIGHT_SIZE.headerHeight + HEIGHT_SIZE.tabsHeight}px)`,
    overflow: 'hidden',
  },
});
