import { ROUTES } from '@/app/consts/routes';
import { getClientCostArticleDict, getDepartmentDict, getEmployeeDict } from '@/dictionary/actions';
import { BACKOFFICE_TABS } from '@/pages/backoffice/consts';
import { IBackofficeState } from '@/pages/backoffice/reducer';
import {
  resetRequestFilters,
  setRequestsFiltersFromQuery,
  updateRequestsFiltersQuery,
} from 'pages/backoffice/Requests/RequestsFilters/actions';
import { getRequestList, resetRequestsList } from 'pages/backoffice/Requests/RequestsList/actions';
import { getRequest, setRequest } from '@/pages/backoffice/Requests/SingleRequest/QuotesList/actions';
import SingleRequestRoot from '@/pages/backoffice/Requests/SingleRequest/SingleRequestRoot';
import { REQUEST_DETAIL_TABS_PATH } from '@/pages/backoffice/Requests/SingleRequest/types';
import { extractFlattenPaths, Route } from '@core-ui/redux-router';
import RequestsRoot from 'pages/backoffice/Requests/RequestsRoot';

const key: keyof IBackofficeState = 'requests';

const routes: Route[] = [
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.REQUESTS})`,
    component: RequestsRoot,
    effects: [
      { events: ['on_enter'], trigger: resetRequestsList },
      { events: ['on_enter'], trigger: resetRequestFilters },
      { events: ['on_enter'], trigger: setRequestsFiltersFromQuery },
      { events: ['on_enter'], trigger: updateRequestsFiltersQuery },
      { events: ['on_enter'], trigger: getRequestList },
      { events: ['on_enter'], trigger: setRequest },
    ],
  },
  {
    key,
    exact: true,
    path: `/:boatId([0-9]+)/:route(${ROUTES.BACKOFFICE})/:backofficeTab(${BACKOFFICE_TABS.REQUESTS})/:requestId/:tabId(${REQUEST_DETAIL_TABS_PATH})?`,
    component: SingleRequestRoot,
    effects: [
      { events: ['on_enter'], trigger: getRequest },
      { events: ['once'], trigger: getDepartmentDict },
      { events: ['once'], trigger: getEmployeeDict },
      { events: ['once'], trigger: getClientCostArticleDict },
    ],
  },
];

export const REQUEST_PATHS = extractFlattenPaths(routes);

export default routes;
