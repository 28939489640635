import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '@/hooks/useAppSelector';
import { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import selector from './selector';

const InvoiceTotal: FC = () => {
  const { shouldShowRub, total } = useAppSelector(selector);

  return (
    <Grid container direction="column" width="20%">
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage id={shouldShowRub ? 'label.total_rub' : 'label.total_eur'} />
      </Typography>

      <Typography variant="h3" fontWeight="semiBold">
        {total}
      </Typography>
    </Grid>
  );
};

export default InvoiceTotal;
